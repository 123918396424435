<template>
  <div class="container">
    <loading v-model:active="isLoading" />
    <transition name="modal fade">
      <div class="modal-mask modal-backdrop">
        <div class="modal-wrapper">
          <div class="modal-dialogs modal-xl" role="document" style="margin: 0 auto;">
            <div class="modal-content">
              <h4 class="mt-3 modal-title d-inline-block text-center">
                Choose Your {{ cardName }}
              </h4>
              <a href="JavaScript:void(0)" class="btn-cross text-right close-modal"
                @click="hideModal($event)">&times;</a>
              <div class="modal-body mdScroll px-5 pb-5">
                <p v-if="error">{{ error }}</p>
                <div class="text-center">
                  <div class="row no-gutters mt-4" v-if="!isLoading">
                    <div class="col-md-4 mb-3" v-for="(item, index) in info" :key="index"
                      :class="isUsed(item.asset_id,item.data.type)">
                      <div class="card modal_card" :class="item.selected ? 'selected' : ''">
                        <div class="card-body p-0">
                          <img class="card-img-top img-fluid" v-show="isImageLoaded" :src="getImgUrl(item.data.img)"
                            alt="" @click.prevent="addCard(item)" @click="isSelected = !isSelected" @load="loaded" />
                          <img v-if="!isImageLoaded" class="card-img-top" :src="'/images/loader-x.gif'" />
                        </div>
                        <div class="asset_name">
                          <h6>{{ cardName }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ApiService from "../services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
export default {
  name: "Modal",
  components: {
    Loading,
  },
  props: ["error", "showModel", "cards", "cardName","isloaded"],
  showModal: true,
  computed: {
    ...mapGetters([
      "userBuild",
    ]),
  },
  data() {
    return {
      info: null,
      isLoading: false,
      isImageLoaded: false,
      apiRes: null,
      isSelected: false,
    };
  },
  mounted() {
      this.init(this.cardName);
  },
  methods: {
    hideModal(e) {
      this.$emit("w-modal-hide-click", e);
    },
    loaded() {
      this.isImageLoaded = true;
    },
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=500";
    },
    addCard(item) {
      this.info = this.info.map((row, index) => {
        if (item.asset_id == row.asset_id) {
          this.info[index].selected = true;
        } else {
          this.info[index].selected = false;
        }
        return row;
      });
      this.$store.commit("addCard", item);
      this.hideModal();
    },
    isUsed(id, type){
      let cls="";
      for (let i = 0; i < this.userBuild.length; i++) {
        const element = this.userBuild[i];
        if (element[type.toLowerCase()] == id){
          cls = "used";
        }
      }
      return cls;
    },
    init(type) {
      if(this.apiRes){
        this.info = this.apiRes.filter((row) => row.data.type==type);
      }else{
        this.isLoading = true;
        ApiService.callApi(
          `collection_name=neftygadgets&page=1&limit=100`,
          async (response) => {
            let res = response["data"];
            if (res["success"]) {
              this.apiRes = res["data"];
              this.info = res["data"];
              this.info = this.apiRes.filter((row) => row.data.type==type);
            } else {
              this.info = [];
            }
            this.isLoading = false;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.used{
  opacity: 0.4;
}
.modal_card{
  background-color: transparent;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-backdrop .modal-wrapper .modal-content .card {
  height: auto !important;
}
.modal-wrapper .modal-content {
  background: rgba(0,0,0,0.6) !important;
  /*width: 760px !important;*/
  opacity: 0.9;
  border-radius: 46px !important;
  border: 2px dashed #678d8c !important;
  margin: auto;
}

.mdScroll {
  overflow-y: scroll;
  height: 500px;
}
.mdScroll::-webkit-scrollbar {
  width: 4px;
}

.mdScroll::-webkit-scrollbar-thumb {
  background: transparent;
  height: 2px !important;
  border-radius: 20px;
}

.mdScroll:hover::-webkit-scrollbar-thumb {
  background: #766640;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #7b61ff;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-right: none;
  border-left: none;
}

.mdScroll::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 22px;
}

.mdScroll::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 28px;
}

.close-modal {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 40px;
  color: #76a8de;
}
.modal-wrapper .modal-content .modal-body .card {
  width: 100% !important;
  border: 2px dashed #678d8c !important;
  margin-bottom: 20px;
}
.modal-wrapper .modal-content .modal-body .btn {
  background: #fff;
  color: black;
  border-radius: 27px;
  font-weight: bold;
  font-size: 1rem;
  margin: 10px auto;
  display: block;
}
.modal-wrapper .modal-content .card:hover {
  /*box-shadow: 10px 15px 20px rgba(209, 203, 203, 0.6);*/
  transform: translateY(-8px);
  transition: all 0.3;
}
.modal-wrapper .modal-content .card.selected {
  border: 2px solid red !important;
}
.card-img-top{
  padding: 20px;
  height: 70%;
  width: 65%;
}
.modal-wrapper{
  width: 80%;
}
</style>