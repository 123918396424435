<template>
  <div class="container">
    <div class="text-center page_heading">
      <h1>Challenges</h1>
    </div>
    <div class="search_bar">
      <div class="row">
        <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-2 order-lg-1 order-md-1 mb-3 mb-lg-0 mb-md-0">
          <div class="search_btn_group">
            <a href="#" @click="filterByDivision(3)">Stock</a>
            <a href="#" @click="filterByDivision(2)">S.Stock</a>
            <a href="#" @click="filterByDivision(1)"> Masters</a>
          </div>
        </div>
        <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-3 order-lg-2 order-md-2 mb-3 mb-lg-0 mb-md-0">
          <div class="monthly_tabs">
            <a href="#" @click="filter('all')" :class="{activeTab:isActive=='all'}">All</a>
            <a href="#" @click="filter('Weekly')" :class="{activeTab:isActive=='Weekly'}">Weekly</a>
            <a href="#" @click="filter('Monthly')" :class="{activeTab:isActive=='Monthly'}">Monthly</a>
          </div>
        </div>
        <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-1 order-lg-3 order-md-3 mb-3 mb-lg-0 mb-md-0">
          <div class="position-relative search_record float-lg-end float-md-end">
            <input type="search" name="search" placeholder="Search" @input="search($event)">
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <div class="css-table">
        <div class="css-table-header custom_table_head">
          <div class="text-start">CHALLENGE</div>
          <div>Division</div>
          <div>Req. Rank</div>
          <div>PLAYERS</div>
          <div>REWARD</div>
          <div>TYPE</div>
        </div>
        <div class="css-table-body" v-if="loaded">
          <div class="css-table-row" v-for="(item, i) in info" :key="i" :class="item.rarity">
            <div class="mobile_table_header d-none fw-bold">CHALLENGE</div>
            <div>{{item.title}}</div>
            <div class="mobile_table_header d-none fw-bold">Division</div>
            <div>{{item.divison.title}}</div>
            <div class="mobile_table_header d-none fw-bold">Req. Rank</div>
            <div>{{ item.divison.ranked }}</div>
            <div class="mobile_table_header d-none fw-bold">PLAYERS</div>
            <div>{{item.total_registered}}/{{item.max_users}}</div>
            <div class="mobile_table_header d-none fw-bold">REWARD</div>
            <div>{{ item.divison.reward }}</div>
            <div class="mobile_table_header d-none fw-bold">TYPE</div>
            <div>{{item.type}}</div>
            <div v-if="!doesExpired(item)" class="d-flex">
              <Countdown :deadline="timeLeft(item)" countdownSize="0.1rem" :showDays="false" :showLabels="false"
                :flipAnimation="false" />
            </div>
            <div class="action_btn red_btn table_action_btn"
              v-if="doesExpired(item) && item.total_registered!=item.max_users">
              <a href="javascript:void(0)" @click="join(item)">
                <span>Join</span>
              </a>
            </div>
            <div class="action_btn red_btn table_action_btn" v-if="item.total_registered ==item.max_users">
              <a :href="`/viewchallenge/${item.id}`">
                <span>View</span>
              </a>
            </div>
            <template v-if="currentUser=='neftygadgets'">
              <div class="action_btn action_btn green_btn int_btn">
                <a href="javascript:void(0)" @click="adminAction('init.bracket',item.id)"><span>Init</span></a>
              </div>
              <div class="action_btn red_btn table_action_btn" @click="adminAction('rm.challenge',item.id)">
                <a href="javascript:void(0)"><span>Remove</span></a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <characterModal :error="error" v-if="showWModal" :clickedDivision="clickedDivision" v-on:hide="hideModal()" />
  </div>
</template>

<script>
import ApiService from "../services";
import CharacterModal from "../components/CharacterModal";
import moment from "moment";
import { Countdown } from "vue3-flip-countdown";
export default {
  name: "Challenges",
  components: {
    Countdown,
    CharacterModal,
  },
  data(){
    return{
      info:null,
      api:null,
      showWModal: false,
      loaded: false,
      expired: false,
      isActive: 'all',
      currentUser: localStorage.getItem("wax_user"),
    }
  },
  
  mounted() {
    this.getChallenges()
  },
  methods: {
    timeLeft(item) {
      let cUTC = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss');
      let endDate = moment(item.start_at).format("YYYY-MM-DD HH:mm:ss");
      this.expired = moment(cUTC).isAfter(moment(endDate))
      console.log(this.expired);
      return moment.utc(endDate)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
    },
    doesExpired(item) {
      let cUTC = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss');
      let endDate = moment(item.start_at).format("YYYY-MM-DD HH:mm:ss");
      return moment(cUTC).isAfter(moment(endDate));
    },
    adminAction(action,id){
      let data={
        challenge:id
      }
      if(action=="rm.challenge"){
        data = {
          id: id
        }
      }
      ApiService.transaction(action,data,(res)=>{
        if (res.processed) {
          ApiService.defaultMessage("Transaction success");
          this.getChallenges();
        }
      })
    },
    join(item){
      this.$store.commit("setProjectId",item.id);
      this.clickedDivision = item.division;
      this.showWModal = true
    },
    filter(param){
      this.isActive = param
      if(param=="all"){
        this.info = this.api;
      }else{
        this.info = this.api.filter((res) => res.type==param);
      }
    },
    filterByDivision(param){
      this.isActive = param
      if(param=="all"){
        this.info = this.api;
      }else{
        this.info = this.api.filter((res) => res.division==param);
      }
    },
    search(param){
      this.info = this.api.filter((res) => res.title.includes(param.target.value));
    },
    hideModal(){
      this.showWModal = false
      this.getChallenges();
    },
    getChallenges(){
      ApiService.getChallanges(res => {
        this.$store.commit("setChallenges", res);
        this.api = res
        this.info = res
        this.loaded = true
      });
    }
  },
};
</script>

<style>
.table_action_btn{
  padding: 2px 30px;
  font-size: 14px;
}
.flip-clock{
  width: 140px;
}
</style>