<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light navbar_bg navbar_main">
      <div class="container-fluid">
        <a class="navbar-brand navbar_logo" href="/dashboard">
          <img src="/images/login_logo.png" class="img-fluid main_logo" />
        </a>
        <div class="nav-item mobile_create_btn d-inline-block d-lg-none">
          <div class="action_btn green_btn float-end race_btn nav-link">
            <router-link to="/addassets"><span>Create</span></router-link>
          </div>
        </div>
        <a class="text-truncate dropdown-toggle mobile_user_name d-inline-block d-lg-none d-md-none" href="#" @click="isOpen = !isOpen">
          <img :src="avatar" class="img-fluid avatar">
          <span>{{ username }}</span>
          <ul class="dropdown-menu" :class="isOpen ? 'show' : ''" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <router-link to="/login" class="dropdown-item">Logout</router-link>
            </li>
            <li>
              <!-- <a class="dropdown-item" href="#">Something else here</a> -->
            </li>
          </ul>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
          @click="menuOpen = !menuOpen">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" :class="menuOpen ? 'show' : ''" id="navbarNavDropdown">
          <ul class="dropdown-menu d-inline-block d-lg-none w-100" style="left:0px" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="/dashboard">Dashboard</a></li>
            <li><a class="dropdown-item" href="/addassets">Add Racer</a></li>
            <li><a class="dropdown-item" href="/challenge">challenges</a></li>
            <li><a class="dropdown-item" href="/leaderboard">Leaderboard</a></li>
            <li><a class="dropdown-item" href="/reward">Rewards</a></li>
          </ul>
          <ul class="navbar-nav ms-auto align-items-center d-none d-lg-flex d-md-flex">
            <!-- <li class="nav-item">
              <div class="nav-link ">
                <div class="position-relative">
                  <span class="box_layout">
                    <img src="/images/single_imgBox.png" class="img-fluid" />
                  </span>
                  reward
                  <span class="">
                    <img src="/images/trophy_img.png" class="img-fluid" />
                  </span>
                  <span class=" text-white"> 0002 </span>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-link ">
                <div class="position-relative">
                    <span class="box_layout">
                      <img src="/images/single_imgBox.png" class="img-fluid" />
                    </span>
                    reward
                  <span class="">
                    <img src="/images/trophy_img.png" class="img-fluid" />
                  </span>
                  <span class=" text-white"> 0002 </span>
                </div>
              </div>
            </li> -->
            <li class="nav-item specfic_btn">
              <div class="action_btn green_btn float-end race_btn nav-link">
                <router-link to="/addassets"><span>Create</span></router-link>
              </div>
            </li>
            <li class="nav-item dropdown mt-1" id="user_dropdown">
              <a class="nav-link dropdown-toggle user_name" href="#" @click="isOpen = !isOpen">
                <img :src="avatar" class="img-fluid avatar">
                <span>{{ username }}</span>
              </a>
              <ul class="dropdown-menu" :class="isOpen ? 'show' : ''" aria-labelledby="navbarDropdownMenuLink">

                <li><a class="dropdown-item" href="/dashboard">Dashboard</a></li>
                <li><a class="dropdown-item" href="/addassets">Add Racer</a></li>
                <li><a class="dropdown-item" href="/challenge">challenges</a></li>
                <li><a class="dropdown-item" href="/leaderboard">Leaderboard</a></li>
                <li><a class="dropdown-item" href="/reward">Rewards</a></li>
                <li>
                  <router-link to="/login" class="dropdown-item">Logout</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import router from "../router";
import { mapGetters } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters([
      "avatar",
    ]),
  },
  data() {
    return {
      isOpen: false,
      menuOpen: false,
      username: localStorage.getItem("wax_user")
    };
  },
  methods: {
    logout: function () {
      router.push("/login");
    },
    backPage(){
      router.go(-1);
    },
  },
};
</script>
<style>
.dropdown-menu li{
  margin: 0px;
}
.showCreate_btn .action_btn{
  display: block;
}
.hideBackBtn{
  display: none;
}
.avatar{
  width: 31px;
}
.navbar-toggler{
  padding: 3px !important;
}
.mobile_user_name{
  width: 150px;
  background: #000;
  padding: 3px;
  border-radius: 10px;
  color: #ffffff !important;
}
.mobile_create_btn .nav-link{
  margin-left: 13px !important;
}
@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav .dropdown-menu {
      right: 0;
  }
}
@media (max-width: 767px){
  .navbar_logo{
    margin: 0px !important;
  }
  .main_back_btn {
    padding: 5px 15px !important;
  }
}
</style>