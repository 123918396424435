import axios from 'axios'
import * as waxjs from "@waxio/waxjs/dist";
import Link from 'anchor-link'
import { useToast } from 'vue-toastification'
const toast = useToast()
import {
    isWebUri
} from 'valid-url';
import moment from 'moment';
import BrowserTransport from 'anchor-link-browser-transport'
var keys = null
var laccount = null
const assetUrl = process.env.VUE_APP_API_ASSET_URL;
if (localStorage.getItem("ual-wax:autologin") != null) {
    let ualWax = JSON.parse(localStorage.getItem("ual-wax:autologin"))
    keys = ualWax ? ualWax.pubKeys : null
}
const waxOptions = {
    rpcEndpoint: process.env.VUE_APP_CHAIN_FULL_URL,
    userAccount: localStorage.getItem("wax_user"),
    pubKeys: keys,
    tryAutoLogin: false
}
var wax = new waxjs.WaxJS(waxOptions);
const genericCall = async(params, callback) => {
    return await axios
        .get(
            `${assetUrl}?owner=${localStorage.getItem(
            "wax_user"
          )}&${params}`
        )
        .then(callback)
}
const getAssetById = async(id, callback) => {
    if (id) {
        return await axios
            .get(
                `${process.env.VUE_APP_API_ASSET_URL}/${id}`
            )
            .then((res) => res.data.data)
            .then(callback)
    } else {
        callback(false);
        return false;
    }
}

const genericCallSales = async(id, callback) => {
    return await axios
        .get(
            `https://wax.api.atomicassets.io/atomicmarket/v1/assets/${id}`
        )
        .then(callback)
}
const calculatePrice = (price) => {
    return `${Number(price.average/100000000).toFixed(2)} ${price.token.token_symbol}`;
}
if (localStorage.getItem("wax_user") != null) {
    laccount = localStorage.getItem("wax_user")
}

const getImgUrl = (hash, size) => {
    size = size || "&w=300&h=300";
    if (isWebUri(hash)) {
        return hash;
    }
    let url = 'https://ipfs.io/ipfs/' + hash;
    return '//images.weserv.nl/?url=' + url + size;
}
const callApi = async(params, callback) => {
    return await axios
        .get(`${process.env.VUE_APP_API_ASSET_URL}?owner=${localStorage.getItem('wax_user')}&page=1&limit=100&${params}`)
        .then(callback)
}
const link = new Link({
    transport: new BrowserTransport({
        requestStatus: false
    }),
    chains: [{
        chainId: process.env.VUE_APP_CHAIN_ID,
        nodeUrl: process.env.VUE_APP_CHAIN_FULL_URL,
    }],
})

const getTable = async(table, lowerBound = null, callBack, onError) => {
    return await wax.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 100,
        table: table,
        lower_bound: lowerBound
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res.rows;
        }
        return null;
    }).then((res) => callBack(res)).catch(onError)
}
const rpc = async(table, userName, lowerBound = null, upper_bound = null, callBack, onError, limit = 100) => {
    // const loginUser = await getSession();
    userName = userName || process.env.VUE_APP_CONTRACT;
    return await wax.rpc.get_table_rows({
        scope: userName,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: limit,
        table: table,
        upper_bound: upper_bound,
        lower_bound: lowerBound,
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res.rows;
        }
        return null;
    }).then((res) => callBack(res)).catch(onError);
}
const getItemUsage = async(assetid = null, callBack, onError) => {
    return await wax.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 1,
        table: "itemusage",
        lower_bound: assetid,
        upper_bound: assetid,
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res.rows;
        }
        return null;
    }).then((res) => callBack(res)).catch(onError)
}

const getAccount = async(callBack) => {
    laccount = localStorage.getItem("wax_user");
    return await axios
        .post(`${process.env.VUE_APP_CHAIN_API}/get_account`, {
            account_name: laccount ? laccount : localStorage.getItem("wax_user"),
        }).then(callBack)
}
const historyApi = async(action, data, callBack) => {
    laccount = localStorage.getItem("wax_user");
    return await axios
        .post(`${process.env.VUE_APP_HISTORY_API}/${action}`, data).then(callBack)
}
const getBalance = async(callBack) => {
    return await axios
        .post(`${process.env.VUE_APP_CHAIN_API}/get_currency_balance`, {
            account: localStorage.getItem("wax_user"),
            code: "code",
            symbol: "sym",
        }).then(callBack)
}
const getChallanges = async(callBack) => {
    return await getTable('challenges', null, async(res) => {
        if (res) {
            for (let i = 0; i < res.length; i++) {
                const row = res[i];
                await rpc("divisions", false, row.division, row.division, (divi) => {
                    res[i].divison = divi[0]
                }, 1)
            }
        }
        return res;
    }).then(callBack);
}
const viewChallanges = async(currentChallengeId, callBack) => {
    console.log(currentChallengeId);
    return await getTable('brackets', null, async(res) => {
        var info = res.filter(item => item.challenge == currentChallengeId)
        for (let i = 0; i < info.length; i++) {
            const row = info[i];
            await rpc("challenges", false, row.challenge, row.challenge, (divi) => {
                info[i].challengeDetails = divi[0]
            }, 1)
        }
        return info;
    }).then(callBack);
}
const getUserBuild = async(callBack) => {
    return await rpc("racer.build", null, null, null, async(res) => {
        let users = await res.filter(
            (el) => {
                if (el.account == localStorage.getItem("wax_user")) {
                    return el;
                }
            }
        );
        for (let i = 0; i < users.length; i++) {
            const row = users[i];
            await rpc("divisions", false, row.division, row.division, (divi) => {
                users[i].divison = divi[0]
            }, 1)
        }
        return users;
    }, (error) => errorFormat(error)).then(callBack);
}
const doSign = async(action, onSuccessCallback, onErrorCallback) => {
    await link.restoreSession(process.env.VUE_APP_NAME).then((session) => {
        session.transact({
            actions: [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(async(res) => {
            verifyTrans(res, onSuccessCallback);
        }).catch(onErrorCallback)
    }).catch(async(error) => {
        console.log(error);
        const identity = await link.login(process.env.VUE_APP_NAME)
        const { session } = identity
        session.transact({
            actions: [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(async(res) => {
            verifyTrans(res, onSuccessCallback);
        }).catch(onErrorCallback)
        return error;
    })
}
const verifyTrans = async(res, onSuccessCallback) => {
    // let status = true;
    setTimeout(async() => {
        // while (status) {
        //     const trans = await historyApi("get_transaction_status", { traces: false, id: res.processed.id }, (r) => r.data);
        //     console.log(trans);
        //     if (Object.hasOwn(trans, 'id')) {
        //         status = false;
        //         onSuccessCallback(res);
        //     } else if (Object.hasOwn(trans, 'error') && trans.error.name == "tx_not_found") {
        //         status = true;
        //     } else {
        //         status = false;
        onSuccessCallback(res);
        //     }
        // }
    }, 1000);
}
const signWithWax = async(action, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    let isAutoLoginAvailable = await wax.isAutoLoginAvailable();
    if (!isAutoLoginAvailable) {
        await wax.login();
    }
    try {
        await wax.api.transact(action, {
            blocksBehind: 3,
            expireSeconds: 120,
            broadcast: true,
            sign: true,
        }).then(async(res) => {
            verifyTrans(res, onSuccessCallback);
        }).catch(onErrorCallback)
    } catch (error) {
        return onErrorCallback(error)
    }
}
const isLogin = () => {
    // check local store and also expiry of session
    if (localStorage.getItem('wax_user')) {
        var sessionDate = localStorage.getItem("ual-session-expiration");
        var d1 = new Date();
        var d2 = new Date(sessionDate);
        return moment(d2).isAfter(moment(d1))
    } else {
        return false
    }
}
const errorFormat = (error) => {
    console.log(error.message, typeof error);
    if (typeof error == "string") {
        toast.error(error);
        return "Something went wrong."
    }
    if (!error.error && error.message) {
        toast.error(error.message);
        return error.message
    }
    if (error.error.what) {
        if (error.error.details) {
            toast.error(error.error.details[0].message);
            return error.error.details[0].message
        }
        return error.error.what;
    } else {
        toast.error("Transaction failed,try again.");
        return "Transaction failed,try again."
    }
}
const defaultMessage = (message) => {
    return toast.success(message);
}
const infoMessage = (message) => {
    return toast.info(message);
}
const claim = async(onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'claimreward',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: {
                account: laccount
            },
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (error) {
        onErrorCallback(error)
    }
}
const makeRequestParams = (searchTerm) => {
    let params = "&page=1&limit=100";
    if (searchTerm) {
        params += "&match=" + searchTerm;
    }
    return params;
}
const getByTempleteId = async(tmpId, callback) => {
    return axios
        .get(`${process.env.VUE_APP_API_TEMPLATE_URL}/lostsailorsl/${tmpId}`)
        .then((res) => callback(res))
}
const userPhoto = async(user, callBack) => {
    user = user || localStorage.getItem("wax_user");
    return await wax.rpc.get_table_rows({
        code: process.env.VUE_APP_PHOTO_CODE,
        json: true,
        limit: 1,
        lower_bound: user,
        scope: process.env.VUE_APP_PHOTO_CODE,
        table: "photos",
        upper_bound: user
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res.rows;
        }
        return null;
    }).then((res) => callBack(res));
}
const transaction = async(actionName, dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: actionName,
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        console.log(actionData);
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            return await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}

export default {
    errorFormat,
    claim,
    userPhoto,
    doSign,
    isLogin,
    getItemUsage,
    genericCall,
    getAssetById,
    getAccount,
    calculatePrice,
    getByTempleteId,
    callApi,
    getTable,
    transaction,
    signWithWax,
    makeRequestParams,
    genericCallSales,
    historyApi,
    rpc,
    defaultMessage,
    getBalance,
    verifyTrans,
    getChallanges,
    getUserBuild,
    viewChallanges,
    infoMessage,
    getImgUrl
}