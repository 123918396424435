<template>
  <div class="container">
    <div class="text-center page_heading">
      <h1>Rewards</h1>
    </div>
    <div class="search_bar">
      <div class="row">
        <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-2 order-lg-1 order-md-1 mb-3 mb-lg-0 mb-md-0">
          <div class="search_btn_group">
            <a href="#"> Stock</a>
            <a href="#">S.Stock</a>
            <a href="#"> Masters</a>
          </div>
        </div>
        <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-3 order-lg-2 order-md-2 mb-3 mb-lg-0 mb-md-0">
          <div class="monthly_tabs">
            <a href="#">Weekly</a>
            <a href="#">Monthly</a>
            <a href="#">Over all</a>
          </div>
        </div>
        <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-1 order-lg-3 order-md-3 mb-3 mb-lg-0 mb-md-0">
          <div class="position-relative search_record float-lg-end float-md-end">
            <input type="search" name="search" placeholder="Search">
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <div class="css-table">
        <div class="css-table-header custom_table_head">
          <div class="text-start">LEAGUE</div>
          <div>CHALLENGE</div>
          <div>PLAYERS</div>
          <div>TYPE</div>
          <div>REWARD</div>
          <div class="action_btn red_btn">
            <a href="#"><span>CLAIM ALL</span></a>
          </div>
        </div>

        <div class="css-table-body d-block d-lg-none d-md-none">
          <div class="action_btn red_btn">
            <a href="#"><span>CLAIM ALL</span></a>
          </div>
          <div class="css-table-row">
            <div class="mobile_table_header d-none fw-bold">LEAGUE</div>
            <div>Stock</div>
            <div class="mobile_table_header d-none fw-bold">CHALLENGE</div>
            <div>GENESIS CHALLENGE #1</div>
            <div class="mobile_table_header d-none fw-bold">PLAYERS</div>
            <div>24/24</div>
            <div class="mobile_table_header d-none fw-bold">TYPE</div>
            <div>WEEKLY</div>
            <div class="mobile_table_header d-none fw-bold">REWARD</div>
            <div>1</div>
            <div class="action_btn green_btn">
              <a href="#"><span>CLAIM</span></a>
            </div>
          </div>

          <div class="css-table-row">
            <div class="mobile_table_header d-none fw-bold">LEAGUE</div>
            <div>S.Stock</div>
            <div class="mobile_table_header d-none fw-bold">CHALLENGE</div>
            <div>GENESIS CHALLENGE #1</div>
            <div class="mobile_table_header d-none fw-bold">PLAYERS</div>
            <div>24/24</div>
            <div class="mobile_table_header d-none fw-bold">TYPE</div>
            <div>WEEKLY</div>
            <div class="mobile_table_header d-none fw-bold">REWARD</div>
            <div>1</div>
            <div class="action_btn green_btn">
              <a href="#"><span>CLAIM</span></a>
            </div>
          </div>

          <div class="css-table-row">
            <div class="mobile_table_header d-none fw-bold">LEAGUE</div>
            <div>MASTERS</div>
            <div class="mobile_table_header d-none fw-bold">CHALLENGE</div>
            <div>GENESIS CHALLENGE #1</div>
            <div class="mobile_table_header d-none fw-bold">PLAYERS</div>
            <div>20/24</div>
            <div class="mobile_table_header d-none fw-bold">TYPE</div>
            <div>WEEKLY</div>
            <div class="mobile_table_header d-none fw-bold">REWARD</div>
            <div>1</div>
            <div class="action_btn green_btn">
              <a href="#"><span>CLAIM</span></a>
            </div>
          </div>
          <div class="css-table-row">
            <div class="mobile_table_header d-none fw-bold">LEAGUE</div>
            <div>stock</div>
            <div class="mobile_table_header d-none fw-bold">CHALLENGE</div>
            <div>GENESIS CHALLENGE #2</div>
            <div class="mobile_table_header d-none fw-bold">PLAYERS</div>
            <div>12/24</div>
            <div class="mobile_table_header d-none fw-bold">TYPE</div>
            <div>WEEKLY</div>
            <div class="mobile_table_header d-none fw-bold">REWARD</div>
            <div>1</div>
            <div class="action_btn green_btn">
              <a href="#"><span>CLAIM</span></a>
            </div>
          </div>
          <div class="css-table-row">
            <div class="mobile_table_header d-none fw-bold">LEAGUE</div>
            <div>S.Stock</div>
            <div class="mobile_table_header d-none fw-bold">CHALLENGE</div>
            <div>GENESIS CHALLENGE #2</div>
            <div class="mobile_table_header d-none fw-bold">PLAYERS</div>
            <div>19/24</div>
            <div class="mobile_table_header d-none fw-bold">TYPE</div>
            <div>WEEKLY</div>
            <div class="mobile_table_header d-none fw-bold">REWARD</div>
            <div>1</div>
            <div class="action_btn green_btn">
              <a href="#"><span>CLAIM</span></a>
            </div>
          </div>
          <div class="css-table-row">
            <div class="mobile_table_header d-none fw-bold">LEAGUE</div>
            <div>MASTERS</div>
            <div class="mobile_table_header d-none fw-bold">CHALLENGE</div>
            <div>GENESIS CHALLENGE #2</div>
            <div class="mobile_table_header d-none fw-bold">PLAYERS</div>
            <div>24/24</div>
            <div class="mobile_table_header d-none fw-bold">TYPE</div>
            <div>WEEKLY</div>
            <div class="mobile_table_header d-none fw-bold">REWARD</div>
            <div>1</div>
            <div class="action_btn green_btn">
              <a href="#"><span>CLAIM</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reward",
};
</script>

<style>
</style>