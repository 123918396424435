<template>
  <div class="login">
    <div class="bg-img-1">
      <header class="p-0 m-0 login-header"></header>
      <div class="login_main text-center">
        <div class="d-inline-block">
          <img src="images/login_logo.png" class="img-fluid" />
        </div>
        <div class="login_content d-block">
          <label class="success_text login_label_text mt-3 mb-4"
            >NFT COLLECTION on WAX BLOCKCHAIN</label
          >
          <h1 class="shaded_text">CONNECT WALLET to play</h1>
          <h2 class="conect_info">
            Connect your Wax Wallet to view and enter NeftyGadgets Season 1
            Challenges
          </h2>
          <div class="position-relative">
            <div v-if="hideConectBtn">
              <a
                href="#"
                class="connection_btn mt-3"
                @click.prevent="handle_login"
                >Connect wallet</a
              >
            </div>
            <div id="ual-div"></div>
          </div>
          <div class="d-block w-100">
            <a href="#" class="text-white d-inline-block mt-3 fw-bold"
              >How to play?</a
            >
          </div>
          <div class="d-block w-100">
            <a href="#" class="text-white d-inline-block mt-1">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginService from "../services/LoginService";
export default {
  name: "Login",
  data() {
    return {
      ual: null,
      showPopup: false,
      // showLoginBtns: false,
      hideConectBtn: true,
    };
  },
  inheritAttrs: false,
  mounted() {
    localStorage.removeItem("ual-session-authenticator");
    localStorage.removeItem("ual-session-expiration");
    localStorage.removeItem("autoLogin");
    localStorage.removeItem("loglevel:webpack-dev-server");
    localStorage.removeItem("ual-wax:autologin");
    localStorage.removeItem("wax_user");
    localStorage.clear();
    const loginService = new LoginService();
    this.ual = loginService.handle_login();
  },
  methods: {
    // ConnectToLogin(){
    //   //alert("sdfsd");
    //   this.showLoginBtns = true;
    //   this.showConectBtn = false;
    //   this.handle_login();
    // },
    handle_login: function () {
      this.hideConectBtn = false;
      var [buttonUAL] = document.body.getElementsByClassName("ual-button-gen");
      if (this.ual) {
        this.ual.init();
        buttonUAL.click();
        let elements = document.body.getElementsByClassName("ual-button-gen");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      } else {
        const loginService = new LoginService();
        this.ual = loginService.handle_login();
        this.ual.init();
        buttonUAL.click();
        let elements = document.body.getElementsByClassName("ual-button-gen");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },
  },
};
</script>

<style>
/*.ual-auth-text{
  font-size: 1rem !important;
}
.fa-chevron-right{
  padding: 14px !important;
  font-size: 1em !important;
}*/
#ual-modal {
  z-index: 1000;
  background: transparent !important;
  position: relative !important;
  left: inherit !important;
  top: inherit !important;
  height: auto !important;
}
#ual-learnMoreContainer {
  display: none;
}
.ual-button-gen {
  display: none !important;
}
#ual-authenticators-list {
  display: flex !important;
  position: relative;
}
.ual-auth-button {
  display: flex !important;
  width: 185px !important;
  margin-right: 10px !important;
  height: 53px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ual-auth-text {
  display: inline-block !important;
  padding: 20px 4px 17px 10px !important;
  font-size: 10px !important;
  width: 137px !important;
}
#ual-modal-selection-content {
  font-family: "Source Sans Pro", sans-serif;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  margin: 7% auto;
  padding: 30px;
  border-radius: 6px;
  width: 428px !important;
  position: relative;
  top: inherit;
  left: inherit;
  transform: inherit;
  padding: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.fa-chevron-right {
  font-size: 16px !important;
  float: right;
  margin: 12px 6px;
}

.ual-modal-content-description {
  color: #fff !important;
  width: 246px !important;
  font-size: 1.2em !important;
  margin: 0.7em auto;
}
.ual-modal-close {
  display: none;
}
/*#ual-modal-selection-content{
  margin-top: 10px !important;
}*/
.ual-auth-icon {
  margin-top: auto !important;
}
.ual-auth-icon-wrapper {
  display: flex !important;
}
</style>