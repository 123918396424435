<template>
  <Navbar />
  <Footer />
  <router-view></router-view>
</template>

<script>
import router from "../router";
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";
export default {
  name: "mainView",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      gameOver: null,
      hoursLeft: null,
      account: null,
      currentRoute: null,
      expiredDate: null,
      winningToken: 0,
    };
  },
  mounted() {
    if (!localStorage.getItem("wax_user")) {
      router.push("/login");
    }
    this.$store.dispatch("getUserAvatar");
  },
  methods: {
    logout: function () {
      router.push("/login");
    },
  },
};
</script>