import { UALJs } from "ual-plainjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";

// login functio
var ual = null
export default class LoginService {
    constructor() {
        this.chain = {
            chainId: process.env.VUE_APP_CHAIN_ID,
            rpcEndpoints: [{
                protocol: "https",
                host: process.env.VUE_APP_CHAIN_URL,
                port: '443',
            }, ],
        };
        this.wax = new Wax([this.chain], { appName: process.env.VUE_APP_NAME });
        this.anchor = new Anchor([this.chain], { appName: process.env.VUE_APP_NAME });
        this.appName = process.env.VUE_APP_NAME;
        ual = new UALJs(this.loginCallBack, [this.chain], this.appName, [this.wax, this.anchor]);
    }
    setUal(myAppRoot) {
        ual = new UALJs(this.loginCallBack, [this.chain], this.appName, [this.wax, this.anchor], myAppRoot);
    }
    loginCallBack(users) {
        if (users[0].session) {
            localStorage.setItem("ual-session-authenticator", "anchor")
        } else {
            localStorage.setItem("ual-session-authenticator", "Wax")
        }
        localStorage.setItem('wax_user', users[0].accountName)
        localStorage.setItem(UALJs.SESSION_ACCOUNT_NAME_KEY, users[0].accountName)
        window.location.href = '/dashboard'
    }
    handle_login() {

        //auth partners
        // login component load to HTML
        const myAppRoot = {
            containerElement: document.getElementById('ual-div')
        }

        // validate authentication
        this.setUal(myAppRoot);
        (async() => {
            await ual.init();
        })()

        // hide UAL button load from liabary after click
        // const [buttonUAL] = document.body.getElementsByClassName('ual-button-gen');
        // buttonUAL.click();
        // var popups = document.getElementsByClassName('ual-button-gen'),
        //     i = popups.length;
        // if (i) {
        //     try {
        //         do {
        //             i--;
        //             if (popups)
        //                 popups[i].remove();
        //         } while (i >= 0)
        //     } catch (error) {
        //         console.warn(error)
        //     }
        // }
        return ual
    }
    getSession() {
        // validate authentication
        const auth = ual.getAuthenticators()
        ual.attemptSessionLogin(auth.availableAuthenticators);
        return ual
    }
    getLoginUser() {
        const activeUser = localStorage.getItem("wax_user");
        if (ual) {
            if (localStorage.getItem("ual-session-authenticator") == "Wax") {
                ual.loginUser(this.wax)
            } else {
                ual.loginUser(this.anchor, activeUser)
            }
            return ual
        }
        return ual
    }
    sessionLogin() {
        // const activeUser = localStorage.getItem("wax_user");
        // if (ual) {
        //     if (localStorage.getItem("ual-session-authenticator") == "Wax") {
        //         ual.loginUser(this.wax)
        //     } else {
        //         ual.loginUser(this.anchor, activeUser)
        //     }
        //     return ual
        // }
        // return ual
        const authenticators = ual.getAuthenticators();
        const authenticatorName = localStorage.getItem(UALJs.SESSION_AUTHENTICATOR_KEY);
        const sessionAuthenticator = authenticators.availableAuthenticators.find((authenticator) => authenticator.constructor.name === authenticatorName);
        const accountName = localStorage.getItem("wax_user") || undefined;
        ual.loginUser(sessionAuthenticator, accountName).then(res => console.log(res));
    }

}