<template>
  <div class="container">
    <div class="text-center page_heading">
      <h1>Genesis Challenge</h1>
    </div>
    <loading v-model:active="loading" :is-full-page="true" />
    <div class="pb-5" v-if="!loading">
      <div class="genesis_challenge">
        <div class="text-center page_heading d-flex justify-content-center align-items-center">
          <!-- <a href="#" class="arrows">
            <i class="fas fa-angle-left"></i>
          </a> -->
          <h1>{{ info[0].challengeDetails.title }} - HEAT #{{info[0].round}}</h1>
          <!-- <a href="#" class="arrows">
            <i class="fas fa-angle-right"></i>
          </a> -->
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-12 col-md-6 col-lg-5 mb-3" v-for="(row, index) in info" :key="index">
            <div class="heat_info_main">
              <div class="heat_detail">
                <span class="position-relative serial_num">
                  <p class="absolute_value">{{row.id}}</p>
                </span>
                <span class="game_result position-relative">
                  <p class="absolute_value win_condition" v-if="row.winner">W</p>
                </span>
                <span class="player_name position-relative" v-if="row.winner">
                  <p>{{row.winner}}</p>
                  <label>Round {{row.round}}</label>
                </span>
                <span class="player_name position-relative" v-else>
                  <p>{{ row.player1 }}</p>
                  <div class="race_btn green_btn text-end" v-if="currentUser == row.player1">
                    <a href="#" class="genesis_btn" @click.prevent="race(row.id)">
                      <span>Race</span>
                    </a>
                  </div>
                </span>
              </div>
              <div class="heat_detail">
                <span class="position-relative serial_num">
                  <!-- <p class="absolute_value">1</p> -->
                </span>

                <span class="game_result position-relative">
                  <!-- <p class="absolute_value win_condition">W</p> -->
                  <p class="absolute_value lose_condition" v-if="row.winner">L</p>
                </span>
                <span class="player_name position-relative" v-if="row.winner">
                  <p v-if="row.winner==row.player2">{{ row.player1}}</p>
                  <p v-if="row.winner==row.player1">{{ row.player2}}</p>
                </span>
                <span class="player_name position-relative" v-else>
                  <p>{{ row.player2 }}</p>
                  <div class="race_btn green_btn text-end" v-if="currentUser == row.player2">
                    <a href="#" class="genesis_btn" @click.prevent="race(row.id)">
                      <span>Race</span>
                    </a>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12">
          <div class="more_heat text-center">
            <span>Next Heat..</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  name: "GenesisChallenge",
  data() {
    return {
      info: null,
      loading:true,
      currentUser: localStorage.getItem("wax_user")
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    race(id) {
      let data = {
        account: this.currentUser,
        bracketid:id
      }
      ApiService.transaction("race", data, (res) => {
        if (res.processed) {
          ApiService.defaultMessage("Transaction success");
          this.init();
        }
      })
    },
    init(){
      ApiService.viewChallanges(this.$route.params.id, async (res) => {
        this.info = res
        if(this.info.length){

          this.loading = false;
        }else{
          ApiService.infoMessage("Race is not initialized by admin, please wait.");
        }
      });
    }
  },
};
</script>

<style>
</style>