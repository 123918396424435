<template>
  <div class="col-12 col-xl-5 col-lg-6 col-md-6">
    <section v-if="loaded && isLoaded">
      <div class="info_main p-3 clearfix">
        <div class="d-flex info_text">
          <!-- <label class="text-white">How to Play?</label> -->
          <div class="me-4 d-inline-block garage_img">
            <div class="asset_inner">
              <img :src="machineImg" class="img-fluid" />
            </div>
          </div>
          <div class="league_info">
            <!-- <label class="text-white"><strong>Master League</strong></label> -->
            <div class="timeBlock">
              <h5 class="text-start">{{userBuild.divison.title}}</h5>
              <!-- <h2><button class="pulse btn btn-success">Select</button></h2> -->
            </div>
            <!-- <span class="garage_closeIcon">
              <i class="far fa-times-circle"></i>
            </span> -->
            <p class="text-white" v-if="pcData">{{pcData.name}} #{{pcData.id}}</p>
            <p class="text-white" v-if="machineData">{{machineData.name}} #2</p>
            <p class="text-white" v-if="characterData">{{ characterData.name}} #{{ characterData.id}}</p>
            <p class="text-white" v-if="bonusData">{{ bonusData.name}} #{{ bonusData.id}}</p>
          </div>
        </div>
        <div class="action_btn green_btn float-end race_btn">
          <a href="/challenge"><span>Go Race</span></a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ApiService from "@/services";
export default {
  name: "RacerBuild",
  props: ["userBuild", "loaded"],
  data() {
    return {
      isImageLoaded: false,
      localLoaded: false,
      pcImg: "/images/loader.gif",
      machineImg: "/images/loader.gif",
      characterImg: "/images/loader.gif",
      bonusImg: "/images/loader.gif",
      pcData:null,
      machineData:null,
      characterData:null,
      bonusData:null,
    };
  },
  computed: {
    isLoaded: function () {
      return this.loaded;
    },
  },
  created() {
    if(this.isLoaded){
      ApiService.getAssetById(this.userBuild.pc,(res)=>{
        if(res){
          this.pcImg = ApiService.getImgUrl(res.data.img, false);
          this.pcData = res.data;
        }
      });
      ApiService.getAssetById(this.userBuild.machine,(res)=>{
        if(res){
          this.machineImg = ApiService.getImgUrl(res.data.img, false);
          this.machineData = res.data;
        }
      });
      ApiService.getAssetById(this.userBuild.character,(res)=>{
        if(res){
          this.characterImg = ApiService.getImgUrl(res.data.img, false);
          this.characterData = res.data;
        }
      });
      ApiService.getAssetById(this.userBuild.bonus,(res)=>{
        if(res){
          this.bonusImg = ApiService.getImgUrl(res.data.img, false);
          this.bonusData = res.data
        }else{
          this.bonusImg = false;
        }
      });
    }
  },
  methods: {
    getImgUrl(pet, isVid) {
      return ApiService.getImgUrl(pet, isVid);
    },
  },
};
</script>

<style>
a {
  text-decoration: none !important;
}

.select-btn {
  border: 1px solid gray;
  width: 100px;
  text-align: center;
  height: 36px;
  margin: 0 auto;
  font-size: 20px;
  color: #fff;
  border-radius: 10px;
  line-height: 30px;
}
/*.garage_img img{
  height: 100%;
}*/
</style>