import { createRouter, createWebHistory } from 'vue-router';
import Garage from '../views/Garage.vue';
import Login from '../views/Login.vue';
import AddAssets from '../views/AddAssets';
// import Garage from '../views/Garage';
import Dashboard from '../views/Dashboard';
import Challenges from '../views/Challenges';
// import ViewChallenge from '../views/ViewChallenge';
import Reward from '../views/Reward';
import Leaderboard from '../views/Leaderboard';
import GenesisChallenge from '../views/GenesisChallenge';
import ApiService from '../services';
import mainView from '../components/Main';
import LoginLayout from '../components/LoginLayout';
const routes = [{
        path: '/',
        redirect: '/login',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/garage',
            name: 'Garage',
            props: true,
            component: Garage,
            meta: {
                sideBarKey: 'Garage',
            },
        }, ],
    },

    {
        path: '/addassets',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/addassets',
            name: 'AddAssets',
            component: AddAssets,
            meta: {
                sideBarKey: 'AddAssets',
            },
        }, ],
    },
    {
        path: '/dashboard',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
                sideBarKey: 'Dashboard',
            },
        }, ],
    },
    {
        path: '/challenge',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/challenge',
            name: 'challenge',
            component: Challenges,
            meta: {
                sideBarKey: 'challenge',
            },
        }, ],
    },
    {
        path: '/reward',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/reward',
            name: 'reward',
            component: Reward,
            meta: {
                sideBarKey: 'reward',
            },
        }, ],
    },
    {
        path: '/leaderboard',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/leaderboard',
            name: 'leaderboard',
            component: Leaderboard,
            meta: {
                sideBarKey: 'leaderboard',
            },
        }, ],
    },
    {
        path: '/viewchallenge',
        component: mainView,
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: ':id',
            name: 'viewchallenge',
            component: GenesisChallenge,
            meta: {
                sideBarKey: 'viewchallenge',
            },
        }],
    },
    {
        path: '/login',
        component: LoginLayout,
        children: [{
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                sideBarKey: 'login',
            },
        }, ],
    },
];
const isLogin = function() {
    return ApiService.isLogin();
};
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;