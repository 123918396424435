<template>
  <div>
    <!-- <footer>
      <div
        class="
          inner_footer
          d-flex
          justify-content-between
          align-items-center
          p-2
          w-100
        "
      >
        <ul>
          <li><a href="#" class="text-white">Help</a></li>
          <li><a href="#" class="text-white">Privacy & Policy</a></li>
        </ul>
        <div class="app_version">
          <label class="text-white">Version: 1.01</label>
        </div>
      </div>
    </footer> -->
  </div>
</template>