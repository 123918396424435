<template>
  <div class="bg-img-2">
    <div class="container">
      <div class="row">
        <div class="w-100 d-inline-block page_heading text-center mb-3">
          <h1>ADD RACERS</h1>
        </div>
        <!-- <div class="col-12 col-md-3 col-lg-3">
          <SideBar></SideBar>
        </div> -->
        <div class="col-12 col-md-12 col-lg-12">
          <div class="row roomCards justify-content-center page_start text-center">
            <div class="col-6 col-md-3 col-lg-3 mb-4">
              <div class="card custom_card">
                <div class="card-body" v-if="!selectedPc">
                  <div class="d-flex flex-column justify-content-center">
                    <h4>PC</h4>
                    <a href="JavaScript:void(0)" class="text-center plus_icon" @click="showSelection('PC')">
                      <i class="fal fa-plus mt-3 fa-3x"></i>
                    </a>
                  </div>
                </div>

                <div class="img_wrapper" v-if="selectedPc">
                  <a href="#" class="img-position img-padding">
                    <div class="img-prev">
                      <img class="card-img-top" :src="getImgUrl(selectedPc.img)" alt=""
                        @click.prevent="showSelection('PC')" />
                    </div>
                  </a>
                </div>
                <p class="teams_box_text">Select Your PC To Access challenges</p>
              </div>
            </div>
            <div class="col-6 col-md-3 col-lg-3 mb-4">
              <div class="card custom_card">
                <div class="card-body" v-if="!selectedMachine">
                  <div class="d-flex flex-column justify-content-center">
                    <h4>Machine</h4>
                    <a href="JavaScript:void(0)" class="text-center plus_icon"
                      @click="showSelection('Downhill Racer Card')">
                      <i class="fal fa-plus mt-3 fa-3x"></i>
                    </a>
                  </div>
                </div>
                <div class="img_wrapper" v-if="selectedMachine">
                  <a href="#" class="img-position img-padding">
                    <div class="img-prev">
                      <img class="card-img-top" :src="getImgUrl(selectedMachine.img)" alt=""
                        @click.prevent="showSelection('Downhill Racer Card')" />
                    </div>
                  </a>
                </div>
                <p class="teams_box_text">Select your Machine Card to Race With</p>
              </div>
            </div>
            <div class="col-6 col-md-3 col-lg-3 mb-4">
              <div class="card custom_card">
                <div class="card-body" v-if="!selectedChar">
                  <div class="d-flex flex-column justify-content-center">
                    <h4>Character</h4>
                    <a href="JavaScript:void(0)" class="text-center plus_icon" @click="showSelection('Character Card')">
                      <i class="fal fa-plus mt-3 fa-3x"></i>
                    </a>
                  </div>
                </div>
                <div class="img_wrapper" v-if="selectedChar">
                  <a href="#" class="img-position img-padding">
                    <div class="img-prev character_image">
                      <img class="card-img-top" :src="getImgUrl(selectedChar.img)" alt=""
                        @click.prevent="showSelection('Character Card')" />
                    </div>
                  </a>
                </div>
                <p class="teams_box_text">Choose your Character Card To Race With</p>
              </div>
            </div>
            <div class="col-6 col-md-3 col-lg-3 mb-4">
              <div class="card custom_card bonus_card">
                <div class="card-body" v-if="!selectedBonus">
                  <div class="d-flex flex-column justify-content-center">
                    <h4>Bonus</h4>
                    <a href="JavaScript:void(0)" class="text-center plus_icon" @click="showSelection('bonus')">
                      <i class="fal fa-plus mt-3 fa-3x"></i>
                    </a>
                    <!-- <small>optional</small> -->
                  </div>
                </div>
                <div class="img_wrapper" v-if="selectedBonus">
                  <a href="#" class="img-position img-padding">
                    <div class="img-prev">
                      <img class="card-img-top" :src="getImgUrl(selectedBonus.img)" alt=""
                        @click.prevent="showSelection('bonus')" />
                    </div>
                  </a>
                </div>
                <p class="teams_box_text">Select a bonus item for special advantages</p>
              </div>
            </div>
            <div class="homeButtons mt-10" v-if="selectedPc && selectedChar && selectedMachine">
              <a href="#" class="position-relative pulse" @click.prevent="create()">
                Create
              </a>
            </div>
          </div>
          <div class="text-center page_heading">
            <h1>GARAGE</h1>
          </div>
          <div class="col-12 col-md-12 col-lg-12">
            <div class="row justify-content-center">
              <racer-build v-for="(userBuild, index) in userBuilds" :userBuild="userBuild" :key="index"
                :loaded="loaded" />
            </div>
          </div>
        </div>
      </div>
      <ShipInfoModal :error="error" v-if="showModal" :cards="info" :cardName="card"
        v-on:w-modal-hide-click="showModal = false" :isloaded="loaded" />
    </div>
  </div>
</template>

<script>
import ShipInfoModal from "../components/ShipInfoModal.vue";
import ApiService from "../services";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import RacerBuild from "../components/RacerBuild.vue";
// import SideBar from "../components/SideBar.vue";
const toast = useToast();
export default {
  name: "AddAssets",
  computed: {
    ...mapGetters([
      "selectedMachine",
      "selectedBonus",
      "selectedPc",
      "selectedChar",
    ]),
  },
  components: {
    ShipInfoModal,
    RacerBuild,
    // SideBar,
  },
  data() {
    return {
      userBuilds: null,
      showModal: false,
      card: null,
      loaded: false,
    };
  },
  mounted() {
    this.getBuild();
  },
  methods: {
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=500";
    },
    showSelection(option) {
      this.card = option;
      this.showModal = true;
    },
    async create() {
      const data = {
        pc: this.selectedPc.asset_id,
        character: this.selectedChar.asset_id,
        machine: this.selectedMachine.asset_id,
        bonus: this.selectedBonus ? this.selectedBonus.asset_id : null,
        account: localStorage.getItem("wax_user"),
      };
      await ApiService.transaction(
        "set.build",
        data,
        (res) => {
          if (res.processed) {
            toast.success("Created...");
            // setTimeout(() => {
            //   this.$router.push("waiting");
            // }, 2000);
          }
        },
        (error) => {
          this.error = ApiService.errorFormat(error);
          toast.error(this.error);
        }
      );
    },
    async getBuild() {
      this.loaded = false;
      ApiService.getUserBuild((res) => {
        this.userBuilds = res;
        this.$store.commit("storeBuild", res);
        this.loaded = true;
      });
    },
  },
};
</script>
<style scoped>
.mt-10 {
  margin-top: 20px !important;
}
.homeButtons a {
  background: #fff;
  border-radius: 20px;
  color: #000;
  padding: 10px 25px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: left !important;
}
/*@media (min-width: 768px){
  .container, .container-md, .container-sm {
      max-width: 880px !important;
  }
}*/
</style>
