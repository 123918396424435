<template>
  <div class="container">
    <div class="text-center page_heading">
      <h1>LeaderBoard</h1>
    </div>
    <div class="pb-5">
      <div class="search_bar">
        <div class="row">
          <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-2 order-lg-1 order-md-1 mb-3 mb-lg-0 mb-md-0">
            <div class="search_btn_group">
              <a href="#"> Stock</a>
              <a href="#">S.Stock</a>
              <a href="#"> Masters</a>
            </div>
          </div>
          <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-3 order-lg-2 order-md-2 mb-3 mb-lg-0 mb-md-0">
            <div class="monthly_tabs">
              <a href="#">Weekly</a>
              <a href="#">Monthly</a>
              <a href="#">Over all</a>
            </div>
          </div>
          <div class="col-12 col-xl-4 col-lg-4 col-md-4 order-1 order-lg-3 order-md-3 mb-3 mb-lg-0 mb-md-0">
            <div class="position-relative search_record float-lg-end float-md-end">
              <input type="search" name="search" placeholder="Search">
              <i class="fas fa-search"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <div class="css-table">
          <div class="css-table-header custom_table_head">
            <div class="text-start" style="width:10%">Rank</div>
            <div style="width: 20%;">Wallet</div>
            <div>WINS/podiums/races</div>
            <div>TROPHIES</div>
          </div>

          <div class="css-table-body">
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>1</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>asdkjawallet.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>150 - 195 - 300</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>195</div>
            </div>

            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>2</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>weaja5was.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>142 - 172 - 250</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>172</div>
            </div>

            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>3</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>waxkja82.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>120 - 164 - 275</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>164</div>
            </div>
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>4</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>jhwd01.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>119 - 159 - 220</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>159</div>
            </div>
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>5</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>nftwallet2.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>110 - 150 - 759</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>150</div>
            </div>
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>6</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>player1.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>105 - 143 - 759</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>143</div>
            </div>
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>7</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>player1.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>105 - 143 - 759</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>143</div>
            </div>
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>8</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>player1.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>105 - 143 - 759</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>143</div>
            </div>
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>9</div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>player1.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>105 - 143 - 759</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>143</div>
            </div>
            <div class="css-table-row">
              <div class="mobile_table_header d-none fw-bold">Rank</div>
              <div>10 </div>
              <div class="mobile_table_header d-none fw-bold">Wallet</div>
              <div>player1.wam</div>
              <div class="mobile_table_header d-none fw-bold">WINS/podiums/races</div>
              <div>105 - 143 - 759</div>
              <div class="mobile_table_header d-none fw-bold">TROPHIES</div>
              <div>143</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  name: "Leaderboard",
};
</script>

<style>
</style>