<template>
  <div class="col-6" v-if="loaded && isLoaded">
    <div class="info_main p-3 clearfix">
      <div class="d-flex info_text">
        <div class="me-4 d-inline-block garage_img">
          <img :src="characterImg" class="img-fluid" />
        </div>
        <a href="#">
          <img :src="bonusImg" v-if="bonusImg" />
        </a>
        <div class="league_info">
          <div class="timeBlock">
            <h5 class="text-start">Division {{ userBuild.divison.title }}</h5>
          </div>
          <p class="text-white" v-if="pcLoaded">{{pc.name}} #{{pc.id}}</p>
          <div class="action_btn green_btn race_btn">
            <a href="#" @click.prevent="join(userBuild.character)"><span>Select</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services";
export default {
  name: "CharSelectCard",
  props: ["userBuild", "loaded","selectedChallenge"],
  data() {
    return {
      isImageLoaded: false,
      showReadyButton: false,
      pcImg: "/images/loader.gif",
      machineImg: "/images/loader.gif",
      characterImg: "/images/loader.gif",
      bonusImg: "/images/loader.gif",
      pcLoaded:false
    };
  },
  computed: {
    isLoaded: function () {
      return this.loaded;
    },
  },
  created() {
    if(this.isLoaded){
      ApiService.getAssetById(this.userBuild.pc,(res)=>{
        if(res){
          this.pc = res.data;
          this.pcLoaded=true
        }
      });
      ApiService.getAssetById(this.userBuild.machine,(res)=>{
        if(res){
          this.machineImg = ApiService.getImgUrl(res.data.img, false);
        }
      });
      ApiService.getAssetById(this.userBuild.character,(res)=>{
        if(res){
          this.characterImg = ApiService.getImgUrl(res.data.img, false);
        }
      });
      ApiService.getAssetById(this.userBuild.bonus,(res)=>{
        if(res){
          this.bonusImg = ApiService.getImgUrl(res.data.img, false);
        }else{
          this.bonusImg = false;
        }
      });
    }
  },
  methods: {
    getImgUrl(pet, isVid) {
      return ApiService.getImgUrl(pet, isVid);
    },
    join(id) {
      const data = {
        account: localStorage.getItem("wax_user"),
        challenge: Number(this.$store.state.selectedChallenge),
        character: Number(id)
      }
      ApiService.transaction("challenge.in", data, (res) => {
        if (res.processed) {
          ApiService.defaultMessage("Transaction success");
          this.$emit("buildSelected", data);
        }
      })
    },
  },
};
</script>

<style>
a {
  text-decoration: none !important;
}

.select-btn {
  border: 1px solid gray;
  width: 100px;
  text-align: center;
  height: 36px;
  margin: 0 auto;
  font-size: 20px;
  color: #fff;
  border-radius: 10px;
  line-height: 30px;
}
</style>