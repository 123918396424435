<template>
  <div class="customModal_main">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" v-if="loaded">
            <a href="javascript:void(0)" class="garage_closeIcon" @click="$emit('hide' , $events)">
              <i class="far fa-times-circle"></i>
            </a>
            <div class="row" v-if="!userBuilds || userBuilds.length==0">
              <h4>No Racer build matched</h4>
            </div>
            <div class="row" v-else>
              <CharSelectCard v-for="(userBuild, index) in userBuilds" :userBuild="userBuild" :key="index"
                :loaded="loaded" v-on:buildSelected="buildSelected()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CharSelectCard from "../components/CharSelectCard.vue";
import ApiService from "../services"
export default {
  name: "CharacterModal",
  props: ["clickedDivision"],
  data() {
    return {
      userBuilds: null,
      loaded: false,
    };
  },
  components: {
    CharSelectCard,
  },
  mounted() {
    this.getBuild();
  },
  methods: {
    async getBuild() {
      this.loaded = false;
      ApiService.getUserBuild((res)=>{
        this.userBuilds = res.filter(res => res.division == this.clickedDivision);
        this.loaded = true;
      })
    },
    buildSelected(data){
      this.$emit("hide", data);
    }
  },
};
</script>

<style>
  .customModal_main{
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
  }
  .customModal_main .modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: block;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .customModal_main .modal-dialog {
    height: 100vh !important;
    display: flex;
  }
  .customModal_main .modal-body{
    padding: 50px 30px 10px 30px;
    border: 2px dashed #678d8c;
    border-radius: 10px;
    background: rgba(0,0,0,0.6);
    height: 476px;
    overflow-y: auto;
  }
  .customModal_main .modal-content{
    background-color: transparent;
    margin: auto !important;
  }
  .customModal_main .info_main{
    min-height: 170px;
  }
</style>