<template>
  <div class="bg-img-2">
    <div class="container">
      <div class="row">
        <div class="text-center page_heading">
          <h1>GARAGE</h1>
        </div>
        <div class="col-12 col-md-12 col-lg-12">
          <div class="row justify-content-center" v-if="!userBuilds || userBuilds.length==0">
            <div class="col-12 col-xl-5 col-lg-6 col-md-6 justify-content-center">
              <div class="action_btn green_btn nav-link text-center">
                <router-link to="/addassets" class="my-3"><span>Create</span></router-link>
                <h3>No build found.</h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <racer-build v-for="(userBuild, index) in userBuilds" :userBuild="userBuild" :key="index"
              :loaded="loaded" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ShipInfoModal from "../components/ShipInfoModal.vue";
import ApiService from "../services";
import RacerBuild from "../components/RacerBuild.vue";
export default {
  name: "Garage",
  components: {
    // ShipInfoModal,
    RacerBuild,
    // SideBar,
  },
  data() {
    return {
      userBuilds: null,
      // showModal: false,
      // card: null,
      // loaded: false,
    };
  },
  mounted() {
    this.getBuild();
  },
  methods: {
    // getImgUrl(hash) {
    //   let url = "http://ipfs.io/ipfs/" + hash;
    //   console.log(url);
    //   return "//images.weserv.nl/?url=" + url + "&w=500";
    // },
    // showSelection(option) {
    //   this.card = option;
    //   this.showModal = true;
    // },
    // async create() {
    //   const data = {
    //     pc: this.selectedPc.asset_id,
    //     character: this.selectedChar.asset_id,
    //     machine: this.selectedMachine.asset_id,
    //     bonus: this.selectedBonus ? this.selectedBonus.asset_id : null,
    //     account: localStorage.getItem("wax_user"),
    //   };
    //   console.log(data);
    //   await ApiService.transaction(
    //     "set.build",
    //     data,
    //     (res) => {
    //       if (res.processed) {
    //         toast.success("Created...");
    //         // setTimeout(() => {
    //         //   this.$router.push("waiting");
    //         // }, 2000);
    //       }
    //     },
    //     (error) => {
    //       this.error = ApiService.errorFormat(error);
    //       toast.error(this.error);
    //     }
    //   );
    // },
    async getBuild() {
      this.loaded = false;
      ApiService.getUserBuild((res) => {
        this.userBuilds = res;
        this.$store.commit("storeBuild", res);
        this.loaded = true;
      });
    },
  },
};
</script>
<style scoped>
.mt-10 {
  margin-top: 20px !important;
}
.homeButtons a {
  background: #fff;
  border-radius: 20px;
  color: #000;
  padding: 10px 25px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: left !important;
}
</style>
