<template>
  <div class="bg-img-2">
    <div class="container">
      <div class="row">
        <div class="w-100 d-inline-block info_page_heading">
          <h1>Dashboard</h1>
        </div>
        <!-- <div class="col-12 col-md-3 col-lg-3">
          <SideBar></SideBar>
        </div> -->
        <div class="col-12 col-md-12 col-lg-12">
          <div class="row roomCards justify-content-center page_start text-center dashboard_page">
            <div class="col-12 col-lg-6 col-md-6">
              <div class="info_main text-center p-4">
                <div class="d-inline-block py-lg-5 py-md-3 info_text text-uppercase">
                  <label class="shaded_text text-uppercase">Add racers</label>
                  <p class="text-uppercase text-white mt-4">Select Assets to use in Challenges.</p>
                  <!-- <div class="d-flex justify-content-center racer_image">
                    <a href="#" class="m-3" v-for="(item , i) in info" :key="i">
                      <img :src="getImgUrl(item.data.img)" class="img-fluid" alt="pc" />
                    </a>
                    <a href="#" class="m-3">
                      <img src="images/car.png" class="img-fluid" alt="car"/>
                    </a>
                    <a href="#" class="m-3">
                      <img src="images/men.gif" class="img-fluid" alt="Men"/>
                    </a>
                  </div> -->
                  <router-link to="/addassets" class="connection_btn dashboard mt-4 text-uppercase">Go to Garage
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
              <div class="info_main text-center p-4">
                <div class="d-inline-block py-lg-5 py-md-3 info_text text-uppercase">
                  <label class="shaded_text">Leaderboard</label>
                  <p class="text-white mt-4">Win the monthly Leaderboard Prize & Earn a lots of prizes</p>
                  <a href="/leaderboard" class="connection_btn dashboard mt-4">View Leadership</a>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
              <div class="info_main text-center p-4">
                <div class="d-inline-block py-lg-5 py-md-3 info_text text-uppercase">
                  <label class="shaded_text">Claim Rewards</label>
                  <p class="text-white mt-4">Claim rewards from challenges & earn a lots of prizes</p>
                  <a href="/reward" class="connection_btn dashboard mt-4">claim Rewards</a>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
              <div class="info_main text-center p-4">
                <div class="d-inline-block py-lg-5 py-md-3 info_text text-uppercase">
                  <label class="shaded_text">Enter Challenges</label>
                  <p class="text-white mt-4">Select Assets to use in Challenges.</p>
                  <a href="/challenge" class="connection_btn dashboard mt-4">View Challenges</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from "../services";
export default {
  name: "Dashboard",
  data() {
    return {
      info: null,
      isLoading: false,
       isSelected: false,
      // userBuilds: null,
      // showModal: false,
      // card: null,
      // loaded: false,
    };
  },
  mounted(){
    ApiService.callApi(
          `collection_name=neftygadgets&page=1&limit=100`,
          async (response) => {
            let res = response["data"];
            if (res["success"]) {
              this.info = res["data"];
              // console.log(this.info)
            } else {
              this.info = [];
            }
            this.isLoading = false;
          }
        );
  },
  methods:{
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=500";
    },
  }
};
</script>
