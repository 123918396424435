<template>
  <nav class="navbar navbar-expand-lg navbar-light navbar_bg navbar_main">
      <div class="container-fluid">
        <a class="navbar-brand navbar_logo" href="/challenge">
          <img src="/images/login_logo.png" class="img-fluid main_logo" />
        </a>
      </div>
    </nav>
    <router-view></router-view>
</template>

<script>

import router from '../router'
export default {
  name: "LoginLayout",
  mounted(){
    if(!localStorage.getItem('wax_user')) {
        router.push('/login')
    }
  },
  methods: {
    logout: function() {
      router.push('/login')
    }
  },
};
</script>
<style>
  body:after{
    background: transparent;
  }
  .bg-img-1{
    height: auto;
  }
</style>