import { createStore } from "vuex";
import ApiService from '../services/'
export default createStore({
    state: {
        tokenPrice: 0,
        balance: 0,
        username: null,
        selectedMachine: null,
        inventory: null,
        selectedChar: null,
        selectedPc: null,
        selectedBonus: null,
        selectedChallenge: null,
        myAssets: null,
        challeges: null,
        userBuild: null,
        stakes: null,
        avatar: "/images/avatar-default.png",
    },
    actions: {
        getUserAvatar: function({ commit }, user) {
            ApiService.userPhoto(user, (res) => {
                if (res) {
                    commit("setAvatar", { image: res[0].photo_hash });
                }
            });
        },
        getMyAssets: function({ commit }, params) {
            ApiService.getAssets(params, (rows) => {
                if (rows) {
                    commit("setMyAssets", rows);
                }
            })
        },
        getTempateImage: function({ commit }, params) {
            ApiService.getByTempleteId(params.reward, (res) => {
                if (res.data.data) {
                    commit("updateRewardTemplate", { account: params.account, image: res.data.data.immutable_data.img });
                }
            });
        }
    },
    mutations: {
        setAvatar(state, val) {
            if (val) {
                state.avatar = ApiService.getImgUrl(val.image, "&w=100&h=100");
            }
        },
        setMyAssets: function(state, rows) {
            state.myAssets = rows
        },
        setChallenges: function(state, rows) {
            state.challeges = rows
        },
        setProjectId: function(state, rows) {
            state.selectedChallenge = rows
        },
        setMachine: function(state, row) {
            state.selectedMachine = row
        },
        setBonus: function(state, row) {
            state.selectedBonus = row
        },
        setCharacter: function(state, row) {
            state.selectedChar = row
        },
        setPc: function(state, row) {
            state.selectedPc = row
        },
        addBalance(state, val) {
            if (val) {
                val = parseFloat(val.toString());
                state.balance = val
            }
        },
        addUser(state, val) {
            state.username = Number(val)
        },
        addCard(state, val) {
            if (val.data.type == "PC") {
                state.selectedPc = val.data;
                state.selectedPc.asset_id = val.asset_id;
            }
            if (val.data.type == "Character Card") {
                state.selectedChar = val.data;
                state.selectedChar.asset_id = val.asset_id;
            }
            if (val.data.type == "bonus") {
                state.selectedBonus = val.data;
                state.selectedBonus.asset_id = val.asset_id;
            }
            if (val.data.type == "Downhill Racer Card") {
                state.selectedMachine = val.data;
                state.selectedMachine.asset_id = val.asset_id;
            }
        },
        addInventory(state, val) {
            state.inventory = val;
        },
        updateBalance(state, val) {
            state.balance = Number(state.balance) + Number(val)
        },
        decrementBalance(state, val) {
            state.balance = Number(state.balance) - Number(val)
        },
        addMarket(state, val) {
            state.market = val
        },
        storeBuild(state, val) {
            state.userBuild = val
        },
    },
    getters: {
        balance: state => {
            if (state.balance) {
                let numberVal = state.balance
                return new Intl.NumberFormat().format(numberVal)
            }
            return 0;
        },
        myAssets: state => state.myAssets,
        username: state => state.username,
        selectedMachine: state => state.selectedMachine,
        selectedChar: state => state.selectedChar,
        selectedPc: state => state.selectedPc,
        selectedBonus: state => state.selectedBonus,
        inventory: state => state.inventory,
        avatar: state => state.avatar,
        userBuild: state => state.userBuild,
    }
});